import ApiRequest from "../../utils/axiosApiInstance";
import storageService from "../../utils/StorageService";

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  UPDATE_USER_DETAILS,
  UPDATE_USER_WALLET_BALANCE,
  UPDATE_USER_PROMOCODES,
  FETCH_CARDS_SUCCESS,
  ADD_CARDS_SUCCESS,
  ADD_CARDS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_ORDERS_SUCCESS,
  UPDATE_SELECTED_CITY,
  FETCH_BLOG_POSTS_SUCCESS,
  FETCH_BLOG_POSTS_ERROR,
} from "../types";

const initialState = {
  token: storageService.get("token"),
  error: null,
  address: [],
  type: "",
  profileImage: "",
  promocode: "",
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  hashed_password: "",
  provider: "",
  salt: "",
  stripe_customer_id: "",
  special_notes: "",
  socialAccount: [],
  total_amount: 0,
  _id: "",
  devices: [],
  created_at: "",
  updated_at: "",
  signup_date: "",
  __v: 0,
  walletBalance: 0,
  promoCodes: [],
  cards: [],
  addCardError: "",
  orderHistory: [],
  selectedCity: null,
  blogPosts: [],
  blogError: null,
};

export function resetPasswordAction(dispatch, data) {
  dispatch({
    type: RESET_PASSWORD,
    error: null,
  });

  ApiRequest.post("password-reset", data).then((response) => {
    if (response.error || response.errors) {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        error: response.errors,
      });
    } else {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    }
  });
}

// export function registerAction(dispatch, data) {
// dispatch({
//   type: REGISTER,
//   error: null,
// });
// ApiRequest.post('register', data).then((response) => {
//   if (response.error || response.errors) {
//     dispatch({
//       type: REGISTER_ERROR,
//       error: response.errors,
//     });
//   } else {
//     // AsyncStorage.setItem('token', response.token)
//     dispatch({
//       type: REGISTER_SUCCESS,
//       token: response.token,
//     });
//   }
// });
// }

export function logoutAction(dispatch) {
  dispatch({
    type: LOGOUT,
    error: null,
  });
  ApiRequest.post("logout").then((response) => {
    if (response.error || response.errors) {
      dispatch({
        type: LOGOUT_ERROR,
        error: response.hint ? response.hint : response.message,
      });
    } else {
      //AsyncStorage.setItem('token', null)
      dispatch({
        type: LOGOUT_SUCCESS,
        token: null,
      });
    }
  });
}

export function setErrosAction(dispatch, data) {
  dispatch(data);
}

export function userApp(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD:
      return { ...state, ...action };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, ...action };

    case RESET_PASSWORD_ERROR:
      return { ...state, ...action };

    case FORGOT_PASSWORD:
      return { ...state, ...action };

    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, ...action };

    case FORGOT_PASSWORD_ERROR:
      return { ...state, ...action };

    case LOGIN:
      return { ...state, ...action };

    case LOGIN_SUCCESS:
      return { ...state, ...action };

    case LOGIN_ERROR:
      return { ...state, ...action };

    case LOGOUT:
      return { ...state, ...action };

    case LOGOUT_SUCCESS:
      return { ...state, ...action };

    case REGISTER:
      return { ...state, ...action };

    case REGISTER_SUCCESS:
      return { ...initialState, ...action.payload };

    case REGISTER_ERROR:
      return { ...state, ...action };

    case FETCH_USERS_SUCCESS:
      return { ...state, ...action.payload };

    case UPDATE_USER_DETAILS:
      return { ...state, ...action.payload };

    case UPDATE_USER_WALLET_BALANCE:
      return { ...state, walletBalance: action.payload };

    case UPDATE_USER_PROMOCODES:
      return { ...state, promoCodes: action.payload };

    case FETCH_CARDS_SUCCESS:
      return { ...state, cards: action.payload };

    case ADD_CARDS_SUCCESS:
      return {
        ...state,
        cards: [...state.cards, action.payload],
        addCardError: "",
      };

    case ADD_CARDS_ERROR:
      return { ...state, addCardError: action.payload };

    case FETCH_ORDERS_SUCCESS:
      return { ...state, orderHistory: action.payload };

    case UPDATE_SELECTED_CITY:
      return { ...state, selectedCity: action.payload };
    case FETCH_BLOG_POSTS_SUCCESS:
      return { ...state, blogPosts: action.payload, blogError: null };

    case FETCH_BLOG_POSTS_ERROR:
      return { ...state, blogPosts: [], blogError: action.error };
    default:
      return state;
  }
}
